body {
  background: #2e3249 !important;
  color: #fff;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.stream-player-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden !important;
  background-color: black;
}

.stream-header-overlay {
  width: 100%;
  display: flex;
  position: absolute;
  top: 30px;
  padding-left: 130px;
  padding-right: 130px;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
}
 
.live-timer {
  width: auto;
  opacity: .8;
  background-color: red;
  border-radius: 5px;
  /* padding: 3px; */
  padding-left: 50px;
  padding-right: 50px;
  text-align: center;
  color: white;
  font-weight: 700;
  font-size: xx-large;
  letter-spacing: 1px;
  font-family: 'Courier New', Courier, monospace;
}

.live-text {
  display: flex;
  width: auto;
  background-color: red;
  color: white;
  font-weight: 700;
  font-size: 25px;
  letter-spacing: 1px;
  padding-right: 15px;
  margin-bottom: 10px;
}

.blink-dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: white;
  margin: 10px;
}


.blinking {
  -webkit-animation: 1s blink ease infinite;
  -moz-animation: 1s blink ease infinite;
  -ms-animation: 1s blink ease infinite;
  -o-animation: 1s blink ease infinite;
  animation: 1s blink ease infinite;
}

@keyframes blink {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
