.css-1oqqzyl-MuiContainer-root{
    padding: 0px !important;
}
    
    
    
    
    
    
.account-info { 
    padding: 0rem;       
}

.help-btn {
    color: rgba(255, 255, 255, 0.42); 
    caret-color: rgba(255, 255, 255, 0.42);
}


.points-text {
    font-size: 20px;
    color: #ffca4f!important;
}

.pofile-divider {
    border-bottom: 1px solid #2e3448;
    border-top: 1px solid #0d111f;
 }

.user-profile {
    background-color: rgb(26, 33, 54); 
    border-color: rgb(26, 33, 54);
    border-radius: 3px;
    
}

.user-actions {
    position: relative;
    overflow: hidden;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #2e3249 !important;
}


.btn-add-points {
    background-color: rgb(0, 153, 0);
    border-color: rgb(0, 153, 0);
    color: #fff !important;
    margin-right: 5px;
}

.btn-withdraw {
    background-color: #fb8c00 !important;
    border-color: #fb8c00 !important;
    color: #ffff !important;
    margin-left: 5px;
}


.btn-contact {
    background-color: rgb(255, 81, 0);
    border-color: rgb(255, 81, 0);
    color: #ffff !important;
}

.user-actions button {
    width: 100%;
}

/* MAIN CSS */


.grid-container {
    display: grid;
    gap: 10px;
    padding: 10px;
}


.grid-item-8 {
    grid-column: 1 / span 2;
    grid-row: 1;
  }
  
  .grid-item-4 {
    grid-column: 3;
    grid-row: 1 / span 2;
  }

.app-main {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
       
}

/* HEADERS */
.app-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    
}

.descriptioner {
/* width: ; */
/* position: relative; */
overflow: hidden;
display: flex;
width: 100%;
height: 100px;
z-index: 100;
white-space: nowrap;

}

.words {
    position: relative;
    font-size: medium;
    animation: move-words 60s linear infinite;
    margin: 0;
    white-space: nowrap;
  }

  @keyframes move-words {
    0% {
      left: 100%;
    }
    50% {
      left: -200%;
    }
    100% {
        left: -400%;
      }
  }

.betting-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
 }
 
 .bet-container {
    flex-grow: 1;
    text-align: center;
    min-height: 200px
 }
 
 .bet-btn {
    margin-top: 12px;
 }
 
 .bet-btn button {
    background-color: hsla(0,0%,100%,.12)!important;
    border-color: hsla(0,0%,100%,.12);
    color: #fff;
    font-size: 12px;
 }
 
 
 .bets-text {
    font-weight: bolder;
    font-size: 18px;
    color: #fff;
 }
 

 .payout-text {
    color: #fff;
    font-weight: bold;
    font-size: 15px;
 }
 
  
 .bet-text {
    font-weight: bolder;
    font-size: 15px;
    color: #fff;
 }
 
 .winning-text {
    color: #ffeb3b;
    font-weight: bold;
    font-size: 16px;
 }
 
 
.decimal-text {
    font-size: 14px;
}

.text-win {
    color: #ffeb3b;
}
 
 .bet-meron {
    background-color: #f34141;
    text-align: center;
    color: #fff;
    padding: 12px;
    font-size: 1.2rem;
    font-weight: bolder;
 }
 
  
 .bet-wala {
    background-color: #1e81f1;
    flex-grow: 1;
    text-align: center;
    color: #fff;
    padding: 12px;
    font-size: 1.2rem;
    font-weight: bolder;
 }
 
  
 .bet-draw {
    background-color: #090;
    flex-grow: 1;
    text-align: center;
    color: #fff;
    padding: 12px;
    font-size: 1.2rem;
    font-weight: bolder;
 }
 
    
    


.header-text-wrapper {
    /* width: 100%; */
    text-align: center;
    padding: 12px;
    color: #fff;
    font-weight: bold;
    /* z-index: 100; */
    /* background: transparent; */
}




.header-title {
    font-style: italic;
    font-size: 20px;
    font-weight: 600;
}

.header-sub {
    font-size: 13px;
    font-style: italic;
    margin-left: -4px;
    line-height: 0;
    }

.header-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    }


    .app-header-left {
        border-bottom: 50px solid #555;
        height: 0;
        width: 100%;
        flex-grow: 1;
        
    }
    
    
    
    
    .app-header-center {
        color: #fff;
        /* padding: ; */
        text-align: center;    
        justify-content: center;
        border-bottom: 50px solid rgb(26, 33, 54);
        border-left: 25px solid #555;
        height: 0;
        width: 100%;
        /* flex-grow: 1; */
        background-color: rgb(26, 33, 54);
        /* border-color: ; */
    
    }
    
    .app-header-right {
        border-bottom: 50px solid #555;
        border-left: 25px solid rgb(26, 33, 54);
        /* border-right: 25px solid transparent; */
        height: 0;
        width: 100%;
        flex-grow: 1;
    
    }
    


/* MERON */
.h-lf-meron {
    border-bottom: 50px solid#f34141 !important;
}



.h-lf-wala {
    border-bottom: 50px solid #1e81f1 !important;
}

.h-lf-draw {
    border-bottom: 50px solid #090 !important;

}

.h-lf-open {
    border-bottom: 50px solid #090 !important;
}


.h-lf-lastCall {
    border-bottom: 50px solid #ed9c4a !important;
}


.h-lf-close {
    border-bottom: 50px solid #db5b5b !important;
}

.h-lf-cancelled {
    border-bottom: 50px solid #7c7c7c!important;
}

.h-rh-meron {
    border-bottom: 50px solid #f34141 !important;
}


.h-rh-wala {
    border-bottom: 50px solid #1e81f1 !important;
}

.h-rh-draw {
    border-bottom: 50px solid #090 !important;

}

.h-rh-open {
    border-bottom: 50px solid #090 !important;
}


.h-rh-lastCall {
    border-bottom: 50px solid #ed9c4a !important;
}

.h-rh-close {
    border-bottom: 50px solid #db5b5b !important;
}

.h-rh-cancelled {
    border-bottom: 50px solid  #7c7c7c!important;
}



.h-cr-meron {
    border-left: 25px solid #f34141 !important;
}


.h-cr-wala {
    border-left: 25px solid #1e81f1 !important;
}

.h-cr-draw {
    border-left: 25px solid #090 !important;

}

.h-cr-open {
    border-left: 25px solid #090 !important;
}


.h-cr-lastCall {
    border-left: 25px solid #ed9c4a !important;
}

.h-cr-close {
    border-left: 25px solid #db5b5b !important;
}

.h-cr-cancelled {
    border-left: 25px solid #7c7c7c !important;
}


.bg-meron {
    background: #f34141 !important;
}


.bg-wala {
    background:  #1e81f1 !important;
}

.bg-draw {
    background:  #090!important;
}

.bg-cancelled {
    background:  #7c7c7c!important;
}


.text-wala {
    color:   #1e81f1 !important;
}

.text-meron {
color: #f34141 !important;
}


.text-draw {
    color:  #090!important;
}

.bet-input input {
    color: #fff !important;
}


.selected {
    opacity: .6;
}





.header-pad {
    margin-top: 3rem;
    margin-bottom: 100px;
    /* padding-top: 1rem; */
}


/* BETTING TRENDS CSS */

.app-main {

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
       
}

.clickable:hover {
    opacity: 0.5;
    cursor: pointer;
}

.trend-header {
    width: 100%;
    color: #fff;
    background: #303967;
    display: flex !important;
    padding: 12px;
}

.trend-side-header {
    width: 100%;
    color: #fff;
    background: #303967;
    display: flex !important;
}


.trend-header-caption {
    font-size: .75rem!important;
    letter-spacing: .0333333333em!important;
    line-height: 1.25rem;
}



.bets-history-content {
    padding: 12px
}

.w-150 {
    width: 250px !important;
}

.trend-summary-wrapper {
    padding-top: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    overflow-x: auto;
}

.trend-item-container {
    display: flex;
    height: 20px;
    padding: 0px !important;
    background-color: rgb(34, 39, 58);
    border-color: rgb(34, 39, 58);
}

.trend-summary-count {
    height: auto;
    width: 30px;
    border-radius: 0 !important;    
    color: #fff;
    font-size: 10px !important;
    padding-left: 5px;
    padding-right: 5px;
    
}


.trend-summary-title-container {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    color: #fff;
    font-size: 12px !important;
}


.current-points{
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.v-theme-dark {
    background-color: rgb(34, 39, 58);
    border-color: rgb(34, 39, 58);
 }
 
 .divider-bot {
    background-color:  rgb(34, 39, 58) !important;
    border: 1px solid  black !important;
 }
 
 .v-avatar {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: normal;
    position: relative;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
 }

.text-lose {
    color: #828282;
}

.result-banner {
    height: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}



.advance-bets-wrapper { 
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.advance-bet {
    color: #ffeb3b!important;
    caret-color: #ffeb3b!important;
    font-size: .75rem;
    line-height: normal;
}

.web-view {
    background: linear-gradient(180deg,#313751,#313751 75%,#2e3148);
}


.bet-number {
    padding: 10px 5px;
    background: #212121;
    text-align: center;
    min-width: 45px;
    margin-right: 10px;
    color: #f2f2f2;
    border-radius: 0!important;
}

.flex-spacer {
    flex-grow: 1;
}

.cancelled {
    background: #7c7c7c;
    opacity: .8;
}

.draw {
    background: #090;
    opacity: .8;
}

.meron {
    background: #dc5b5a;
    opacity: .8;
}

.wala {
    background: #2a72c3;
    opacity: .8;
}

.meron:hover {
    opacity: 1 !important;
}


.wala:hover {
    opacity: 1 !important;
}

.draw:hover {
    opacity: 1 !important;
}

.trend-container {
    overflow: hidden;
    overflow-x: scroll;
}
.test {
    height: 25px;
    min-width: 25px;
    width: 25px;
}

.trend-item  {
     height: 25px;
    min-width: 25px;
    width: 25px;
    background: #21263a;
    margin: 3px 6px 3px 0;
}

.rounded-0 {
    border-radius: 0!important;
}



.grid-container {
    width: 100vw !important;
    overflow: hidden;
     
 }



.grid-row {
    display: flex;
    
    /* margin: 1rem; */
}

.gcash-img {
border-radius: 5px;
margin: 1.5rem;
}



/* //BTN NAV */
.main-wrapper {
margin-bottom: 100px;
}



.btn-nav {
    position: fixed;
    bottom: 0px;
    background: #272727 !important;
    color: #fff;
    width: 100vw;
}

.btn-nav-wrapper {
    background: #272727 !important;
    color: #fff;
}

.btn-nav button {
    background: #272727 !important;
    color: #fff;
}
/* //ADD POINTS */





.gcash-description {
    font-size: .875rem!important;
    letter-spacing: .0178571429em!important;
    line-height: 1.25rem;
    /* background-color: rgb(161, 218, 251); */
    color: rgb(51, 51, 51);

}
.gcash-wrapper {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
}

.gcash-wrapper:hover {
    opacity: 1;
}

.gcash-people-icon{
    font-size: 40px !important;
}

.gcash-header {
    background-color: rgb(57, 99, 165);
    border-color: rgb(57, 99, 165);
}

.maya-header {
    background-color: rgb(57, 165, 99);
    border-color: #090;
}

.gcash-card-wrapper  {
    background-color: rgb(161, 218, 251);
    color: rgb(51, 51, 51);

}

.card-selected {
    opacity: 1;
    background-color:  rgba(192, 194, 206, 0.2);
}


.maya-card-wrapper  {
    background-color: rgb(200, 255, 230);
    color: rgb(51, 51, 51); 
}





.gcash-list-item  {
border-radius: 5px;
    /* height: 40px; */
}


.gcash-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    
}

.gcash-form-control {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

.gcash-form-input {
    width: 100%;
    
    text-align: left;
    height: 40px;
    padding: 3px;
    font-size: 1.2rem;
    background-color: rgba(192, 194, 206, 0.2);
    border-color: rgba(192, 194, 206, 0.2);
    border-radius: 5px;
    border-color: rgba(192, 194, 206, 0.2);
    padding-left: 1rem;
    color: #fff;
    font-weight: 300 !important;
}




/* //VIDEOS */


#vid-iframe {
    width: 100%;
    height: 450px;
    padding: 10px;
    
}

.text-ellipse {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.video-player {
    /* width: ; */
    margin-top: -90px;
    /* padding-bottom: -20px; */
    /* background-color: rgb(26, 33, 54); */
    border-color: rgb(26, 33, 54);
    min-height: 450px;
    max-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -100;
    width: 100%;
    margin-bottom: 50px;
}



.overlay-play-button {
    /* Used to position the overlay */
    position: relative;
    /* min-heigpx; */
    z-index: 0;
  
  }
  
  .overlay-play-button__overlay {
    /* Position */
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    min-height: 100%;
    max-height: 500px;
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: 0;

  
    /* Add a dark background */
  }
  
  .overlay-play-button__play {
    /* border: 0.25rem solid #fff; */
    border-radius: 9999px;
    /* height: 5rem; */
    width: 5rem;
  
    /* Center the content */
    align-items: center;
    display: flex;
    justify-content: center;
  }
  


  .bg-dark-gradient{
    background: linear-gradient(200deg,#1a2136,#000);
}

.otp-main {
    margin: 0 2rem 2rem 2rem;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* TRANSACTIONS */

.transaction-list {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    overflow-y: auto;
}

.transaction-list-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;    
    border-bottom: 1px solid black;
    padding-bottom: 5px;
    border-bottom: 2px solid #282e40;
    margin-bottom: 2px;
}

.transaction-list-item-text {
    flex-grow: 1;
}

.transaction-list-item-action {
    text-align: right;
}

.dot {
    height: 10px;
    width: 10px;
    background-color: rgb(9, 247, 9);
    border-radius: 50%;
    display: inline-block;
  }



/* //GLOBALS */
.text-yellow {
    color: #ffeb3b!important;
    caret-color: #ffeb3b!important;
}

.chip {
    height: 20px !important;
}


.chip-valid {
    height: 20px !important;
    width: 80px !important;
}

.h-20 {
    height: 20px !important;
}

.h-300 {
    height: 300px;
}

.h-200 {
    height: 200px;
}

.h-100 {
    height: 100px;
}

.m-h-50 {
    min-height: 50px;  
}

.m-w-50 {
    min-width: 50px;  
}



.m-h-100 {
    min-height: 100px;
}

.m-h-150 {
    min-height: 150px;
}

.m-h-200 {
    min-height: 200px;
}

.m-h-300 {
    min-height: 300px;
}


.w-300 {
    width: 300px;
 }

.pr-1 {
    padding-right: .5rem;
}

.text-green {
    color: #4caf50!important;
    caret-color: #4caf50!important;
}

.bg-withdraw {
    background-color: #fb8c00 !important;
    border-color: #fb8c00 !important;
}


.bg-paper {
    background-color: rgb(255, 243, 186);
    border-color: rgb(255, 243, 186);
}

.bg-dark-blue {
    background-color: #1565c0!important;
    border-color: #1565c0!important;
    
}

.bg-gcash-form {
    background-color: rgb(58, 75, 123);
    border-color: rgb(58, 75, 123);
    margin-bottom: 20px;
}

.bg-maya-form {
    background-color: rgb(57, 165, 99);
    border-color: #090;
    margin-bottom: 20px;
}

.dialog-content {
    background-color: rgb(46, 52, 72); 
    /* border-color: rgb(46, 52, 72); */
}


.wala-section {
    background: #1e81f1;
}

.border-0 {
border-radius: 0px !important;
}

.bets-card {
    background-color: rgb(34, 39, 58); border-color: rgb(34, 39, 58);
}

.opacity-7 {
    background-color: rgb(34, 39, 58); 
    opacity: .7 !important;
}

.opacity-8 {
    opacity: .8;
}

.opacity-5 {
    opacity: .5;
}

.opacity-6 {
    opacity: .6;
}

.body-2 {
    font-size: .875rem!important;
    letter-spacing: .0178571429em!important;
    line-height: 1.25rem;
    font-weight: 400;
    font-family: Roboto,sans-serif!important;
}

.text-s-rem {
font-size: 1rem !important;
}

.text-md-rem {
    font-size: 1.2rem !important;
    }
    
    .text-lg-rem {
        font-size: 1.3rem !important;
        }
    
.text-xl-rem {
    font-size: 1.5rem !important;
    }
    
    
    .text-10 {
        font-size: 12px !important;
    }


.text-12 {
    font-size: 12px !important;
}

.text-15 {
    font-size: 15px !important;
}

.text-18{
    font-size: 18px !important;
}

.text-bold {
    font-weight: bold !important;
}

.text-bolder {
    font-weight: bolder !important;
}

@media only screen and (max-width: 900px) {
    .d-none-md {
         display: none !important;
         background-color: lightblue;
    }
    
    .account-info { 
        padding: 1rem;
        padding-top: 2rem;
    }
    
    .main-wrap {
        margin: 0px !important;
        padding-left: -1rem !important;
        padding-right: -1rem !important;
    }
    
    .video-container {
        /* max-width: 00px; */
        max-width: auto;
        padding: 0px;
        margin: 0px;
        /* min-width: 500px; */
        max-height: 500px;
    }
    
    .video-player {
        /* min-height: 470px; */
        max-height: 500px;
    }
    
    .overlay-play-button__overlay {
        /* Position */
        left: 0;
        position: absolute;
        top: 0;
        margin-top: 20px;
        /* Take full size */
        max-height: 500px;
        /* width: 100%; */
        /* min-height: 450px; */
        
        /* Center the content */
        align-items: center;
        display: flex;
        justify-content: center;
      
        /* Add a dark background */
      }
    
  
    
  }
  





@media only screen and (max-width: 600px) {
    .d-none-md {
         display: none !important;
         background-color: lightblue;
    }
    
    .account-info { 
        padding: 1rem;  
        padding-top: 2rem;
    }
    
     
    .video-container {
        /* min-height: 320px; */
        min-width: 100%;
        height: auto;
    }
    
       
    .video-player {
        /* width: ; */
        /* min-height: 470px; */
        min-height: 250px;
        max-height: 400px;
    }
    
      
  .overlay-play-button__overlay {
    /* Position */
    left: 0;
    position: absolute;
    top: 0;
    margin-top: 20px;
    /* Take full size */
    align-items: center;
    display: flex;
    justify-content: center;
  
    /* Add a dark background */
    /* background-color: rgba(0, 0, 0, 1); */
  }
    
  .main-wrap {
    margin: 0px !important;
    padding-left: -1rem !important;
    padding-right: -1rem !important;
    }
    
      
    .app-header-left {
        font-size: 13px;
    }
    
    .app-header-right {
        font-size: 12px;
    }
    
       
  
    
    
  }
  
  
  

@media only screen and (max-width: 300px) {
    .d-none-md {
         display: none !important;
         background-color: lightblue;
    }
    
    .app-header-left {
        font-size: 13px;
    }
    
    .app-header-right {
        font-size: 12px;
    }
        
    .account-info { 
        padding: .5rem;      
        padding-top: 1rem;
        
    }
         
    .video-container {
        height: 250px;
        min-width: 100%;
    } 
    
    .video-player {
        max-height: 200px;
        height: 200px;
    }
  
       
    .overlay-play-button__overlay {
        /* Position */
        left: 0;
        position: absolute;
        top: 0;
        margin-top: 20px;
        /* Take full size */
        width: 100%;
        /* max-height: 450px; */
        /* Center the content */
        align-items: center;
        display: flex;
        justify-content: center;
      
        /* Add a dark background */
        /* background-color: rgba(0, 0, 0, 1); */
      }
        
      .main-wrap {
        margin: 0px !important;
        padding-left: -1rem !important;
        padding-right: -1rem !important;
    }
    
    
  }
