.App {
    text-align: center;
  } 

  .stand {
    background-image: url("stand.png");
    background-size: cover;
    background-position: center;
    height: 380px;
    padding-top: 60px;
    padding-left: 40px;
    padding-right: 30px;
    background-repeat: no-repeat;
  }


  .roleta {
    margin-left: 20px;
  }
  
/*   
  .roleta {
    background-image: url("roleta.png");
    background-size: cover;
    height: 100px;
    width: 100px;
    background-repeat: no-repeat;
  } */
  
  div .roleta:hover {
    transform: rotate(1200deg);
  }
  
  .App-logo-1 {
    /* height: 40vmin; */
    /* pointer-events: none; */
    /* transition: transform 5s; */
    animation: App-logo 1s infinite linear;
  
  }
  
  .App-logo {
    /* height: 40vmin; */
    /* pointer-events: none; */
    /* transition: transform 5s; */
    animation: App-logo 5s infinite linear;
  
  }
  
  
  .App-logo {
    /* height: 40vmin; */
    /* pointer-events: none; */
    /* transition: transform 5s; */
    animation: App-logo 5s infinite linear;
  
  }
  
  
  .App-logo-2 {
    /* height: 40vmin; */
    /* pointer-events: none; */
    /* transition: transform 5s; */
    animation: App-logo 5s infinite linear;
  
  }
  
  
  .App-logo:hover {
    /* transform: rotate(1200deg); */
    /* height: 100px; */
    animation: App-logo 1s infinite linear;
  }
  
  .box {
    margin: 20px;
    padding: 10px;
    display: inline-block;
    width: 100px;
    height: 100px;
    background-color: red;
    font-size: 18px;
    transition-property: background-color font-size transform color;
    transition-timing-function: ease-in;
  }
  
  .transformed-state {
    /* transform: rotate(270deg); */
    background-color: blue;
    color: yellow;
    font-size: 12px;
    transition-property: background-color font-size transform color;
    transition-timing-function: ease-out;
  }
  
  .duration-1 {
    transition-duration: 0.5s;
  }
  
  .duration-2 {
    transition-duration: 2s;
  }
  
  .duration-3 {
    transition-duration: 4s;
  }
  img:hover{
    size: 100px !important;
  }
  
  .App-logo-spin {
    animation: App-logo-spin 15s  ease-in-out;
    /* transition-duration: 1.5s ease-in-out; */
    /* transition-timing-function: ease-in-out; */
  transform: rotate(0deg);
  }
  
  /* .App-logo-spin-2 {
    animation: App-logo-spin  2.5s  ease-in-out;
    transition-duration: 20s ease-in-out;
    transition-timing-function: ease-in-out;
  }
  
  .App-logo-spin-3 {
    animation: App-logo-spin  3.5s  ease-in-out;
    transition-duration: 30s ease-in-out;
    transition-timing-function: ease-in-out;
  }
  
  .App-logo-spin-4 {
    animation: App-logo-spin  3.5s ease-out;
    transition-duration: 30s;
    transition-timing-function: ease-out;
  } */
  
  .App-logo-spin-0 {
    animation-play-state: paused;
    animation: App-logo-spin  1s  ease-in-out;
    animation-play-state: paused;
    transform: rotate(720deg);
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  
  .logo {
    animation: spin 1s  linear ;
    display: inline-block;
    /* transition: 5s all; */
  }
  
  .container {
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* display: inline-block; */
    transition:  all 10s;
  }
  
  .active-btn {
    background-color: #61dafb;
  }
  
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  