.login-main {
    background: linear-gradient(200deg,#1a2136,#000);
    height: 100vh;
    display:  flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 100px;
    padding-top: 100px;
    overflow-y: auto;
}

.login-container {
    display:  flex;
    align-items: center;
    justify-content: center;
}


.login-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.login-wrapper-md {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.banner-wrapper {
    flex-grow: 1;
}

.form-wrapper {
    flex-grow: 1;
}

.input-label {
    font-size: medium !important;
    font-weight: 400 !important;
}


.login-logo {
    max-width: 280px;
    margin-right: 30px;
}

.login-input {
    background-color: rgba(192, 194, 206, 0.2);
    border-color: rgba(192, 194, 206, 0.2);
    border-radius: 5px
}

.text-gray {
    color: #9e9e9e!important;
    caret-color: #9e9e9e!important;
    }